import {Person} from "@/types/mentor"

const mentors: Person[] = [
    {
        id: 1,
        fullName: "Niloufar Karimian",
        jobTitle: "Career Mentor",
        company: "Expat Pie",
        experience: "7",
        location: ["The Netherlands"],
        price: 80,
        about: "As a Project Manager and Talent Acquisition specialist at VU Amsterdam, I combine my MBA degree and my track record in tech and academia across Europe to lead and execute impactful initiatives. I manage multiple International Business Development and HR projects, from writing proposals and defining scopes to managing budgets and authoring policies. I also pioneer the Academic Recruiter role for the Faculty of Science, attracting and hiring top talent in STEM fields.",
        social: {
            linkedin: "https://www.linkedin.com/in/niloufar-karimian-szatmari",
        },
        topics: [
            "Resume Review", "Career Progression", "Phone Screen", "Mock Interview", "Linkedin Improvement", "Sharing Experience"
        ],
        background: {
            languages: [
                "English", "Persian"
            ],
            expertise: "HR",
        },
        experiences: [
            {
                title: "HR Project Manager & Senior Recruiter",
                location: "The Netherlands",
                company: "Vrije Universiteit Amsterdam",
                start: "Nov 2021",
                end: "Present",
            },
            {
                title: "Tech Career Mentor",
                location: "The Netherlands",
                company: "Expat Pie",
                start: "Jan 2022",
                end: "Present",
            },
            {
                title: "Technical Recruiter",
                location: "The Netherlands",
                company: "LevelUp Ventures",
                start: "May 2021",
                end: "Aug 2021",
            },
        ]
    },
    {
        id: 3,
        fullName: "Shaygan Hooshyari",
        jobTitle: "Software Engineer",
        company: "Vio",
        experience: "6",
        location: ["The Netherlands"],
        price: 100,
        about: "I'm a software engineer with 6 years of experience. Worked as backed developer and data engineer in both small and large companies. I'm also active in open source projects both as a maintainer and a contributor.",
        social: {
            linkedin: "https://nl.linkedin.com/in/shayegan-hooshyari",
            web: "https://github.com/glyphack"
        },
        topics: [
            "Technical Task Feedback", "Live Code Mock Interview", "Sharing Experience"
        ],
        background: {
            languages: [
                "English", "Persian"
            ],
            expertise: "Engineering",
        },
        experiences: [
            {
                title: "Software Engineer",
                location: "The Netherlands",
                company: "Vio",
                start: "Jun 2024",
                end: "Present",
            },
            {
                title: "Software Engineer",
                location: "The Netherlands",
                company: "Flexport",
                start: "Jun 2023",
                end: "May 2024",
            },
        ]
    },
    {
        id: 4,
        fullName: "Faezeh Davari",
        jobTitle: "Tech Talent recruiter",
        company: "NFI",
        experience: "5",
        location: ["The Netherlands"],
        price: 80,
        about: "Hi! This is Faezeh! Excited to elevate your job search with three years of recruitment experience. On the education front, I hold a bachelor's in Computer Science and an MBA for my master's. Let's have a chat and navigate your journey towards landing that dream job :)",
        social: {
            linkedin: "https://www.linkedin.com/in/faezehdavari",
        },
        topics: [
            "Resume Review",
            "Career Progression",
            "Phone Screen",
            "Mock Interview",
            "Linkedin Improvement", "Sharing Experience"
        ],
        background: {
            languages: [
                "English", "Persian"
            ],
            expertise: "HR",
        },
        experiences: [
            {
                title: "Corporate Recruiter",
                location: "The Netherlands",
                company: "NFI",
                start: "Jul 2024",
                end: "Present",
            },
            {
                title: "Technical Recruiter",
                location: "The Netherlands",
                company: "Realworks",
                start: "Jul 2022",
                end: "Jul 2024",
            },
        ]
    },
    {
        id: 5,
        fullName: "Reyhane Seraji",
        jobTitle: "Product Manager",
        company: "Tesla",
        experience: "5",
        location: ["The Netherlands"],
        price: 90,
        about: "I'm a product manager with 5 years of experience. I've worked in different areas like customer-faced products, B2B, internal tools, and nowadays mostly pure backend products. It's also been 10 years that I've been doing mentorship as a volunteering side job.",
        social: {
            linkedin: "https://www.linkedin.com/in/reyhane-seraji/",
        },
        topics: [
            "Technical Cv Feedback",
            "Technical Mock Interview",
            "Technical Task Feedback", "Sharing Experience"
        ],
        background: {
            languages: [
                "English", "Persian"
            ],
            expertise: "Product",
        },
        experiences: [
            {
                title: "Product Manager",
                location: "The Netherlands",
                company: "Tesla",
                start: "Feb 2023",
                end: "Present",
            },
            // {
            //     title: "Product Owner",
            //     location: "The Netherlands",
            //     company: "bunq",
            //     start: "Jan 2022",
            //     end: "Dec 2022",
            // },
        ]
    },
    {
        id: 2,
        fullName: "Samira Soltani",
        jobTitle: "Tech Talent recruiter",
        company: "Ilmio Talent Agency",
        experience: "5",
        location: ["Estonia", "Finland"],
        price: 80,
        about: "I have over 5 years of experience in tech talent acquisition. During my years of expertise, I have delivered successful recruitments in European and middle eastern Tech companies. I would be more than happy to help you in your career journey and finding your next adventure.",
        social: {
            linkedin: "https://www.linkedin.com/in/samira-soltani/",
        },
        topics: [
            "Resume Review", "Career Progression", "Phone Screen", "Mock Interview", "Linkedin Improvement", "Sharing Experience"
        ],
        background: {
            languages: [
                "English", "Persian"
            ],
            expertise: "HR",
        },
        experiences: [
            {
                title: "Tech Talent Acquisition specialist",
                location: "Finland",
                company: "Ilmio Talent Agency",
                start: "Jun 2024",
                end: "Present",
            },
            {
                title: "Talent Acquisition specialist",
                location: "Estonia",
                company: "Smart Hiring",
                start: "Aug 2022",
                end: "Present",
            },
            {
                title: "Talent Acquisition specialist",
                location: "Estonia",
                company: "Starship",
                start: "Mar 2022",
                end: "Jun 2022",
            },
        ]
    },
    {
        id: 6,
        fullName: "Amin Bahiraee",
        jobTitle: "Software Developer",
        company: "Wolt",
        experience: "10",
        location: ["Germany"],
        price: 120,
        about: "With 10 years in software development, I specialize in creating Android apps, learning significantly from both challenges and mistakes. My achievements include developing high-usage Android applications for a major Ride-hailing company, each reaching over 10 million users. I also created an analytics SDK installed on 14 million devices, demonstrating my ability to manage large-scale projects. In my diverse career, I've held roles in finance (P.F.C) and food delivery (Wolt) as an Android and Senior Android Engineer. My focus is on mentoring new developers in Android development, emphasizing testing, and code refactoring. I am dedicated to fostering growth and innovation in the field of Android development.",
        social: {
            linkedin: "https://www.linkedin.com/in/amin-bahiraee-75b4ab52",
        },
        topics: [
            "Technical Mock Interview",
            "Technical Task Feedback", "Sharing Experience"
        ],
        background: {
            languages: [
                "English", "Persian"
            ],
            expertise: "Engineering",
        },
        experiences: [
            {
                title: "Senior Android Engineer",
                location: "Germany",
                company: "Wolt",
                start: "Aug 2023",
                end: "Present",
            }
        ]
    },
    {
        id: 7,
        fullName: "Alireza Khaleghi",
        jobTitle: "Data Engineer",
        company: "Van Caem Klerks Group",
        experience: "6",
        location: ["The Netherlands"],
        price: 120,
        about: "I am a Data (Platform) Engineer with over six years of experience, specializing in building and maintaining end-to-end data pipelines and data engineering solutions. My expertise includes using technologies like Azure Data Factory, Azure Databricks, and Rust, with a focus on ensuring efficient data extraction, transformation, and loading processes. I have worked at caemklerks since 2022, where I lead projects and mentor junior team members, and prior to that, I was a Data Engineer at DataChef.co, consulting for CarNext.com. My skill set is further enhanced by a strong background in software engineering and security analysis, alongside proficiency in SQL, Spark, Python, Scala, C++, and Golang.",
        social: {
            linkedin: "https://www.linkedin.com/in/khaleghia/",
        },
        topics: [
            "Technical Cv Feedback",
            "Technical Mock Interview",
            "Technical Task Feedback", "Sharing Experience"
        ],
        background: {
            languages: [
                "English", "Persian"
            ],
            expertise: "Data",
        },
        experiences: [
            {
                title: "Data Engineer",
                location: "The Netherlands",
                company: "Van Caem Klerks Group",
                start: "Oct 2022",
                end: "Present",
            },
            {
                title: "Data Engineer",
                location: "The Netherlands",
                company: "DataChef",
                start: "Jan 2021",
                end: "Oct 2022",
            },
        ]
    },
    {
        id: 8,
        fullName: "Farbod Ahmadian",
        jobTitle: "Data Engineer",
        company: "DataChef",
        experience: "3",
        location: ["The Netherlands"],
        price: 90,
        about: "I'm a Software Engineer with a strong background in designing and implementing robust real-time pipelines and backend applications on AWS. Proficient in Python, Scala, and Golang, with expertise in various data processing frameworks such as Kafka and Spark.",
        social: {
            linkedin: "https://www.linkedin.com/in/farbod-ahmadian/",
            web: "https://github.com/farbodahm"
        },
        topics: [
            "Technical Cv Feedback",
            "Technical Mock Interview",
            "Technical Task Feedback", "Sharing Experience"
        ],
        background: {
            languages: [
                "English", "Persian"
            ],
            expertise: "Data",
        },
        experiences: [
            {
                title: "Data Engineer",
                location: "The Netherlands",
                company: "DataChef",
                start: "Dec 2021",
                end: "Present",
            },
        ]
    },
    {
        id: 9,
        fullName: "Aref Ahmadi",
        jobTitle: "Product Designer",
        company: "Relex Solutions",
        experience: "8",
        location: ["Finland"],
        price: 85,
        about: "I am a product designer with over 8 years of experience. I have experience designing VOD services, data-heavy B2B dashboards and cross-platform design systems.",
        social: {
            linkedin: "https://www.linkedin.com/in/arefaraghi/",
        },
        topics: [
            "Technical Cv Feedback",
            "Technical Mock Interview",
            "Technical Task Feedback", "Sharing Experience"
        ],
        background: {
            languages: [
                "English", "Persian"
            ],
            expertise: "Product Design",
        },
        experiences: [
            {
                title: "Product Designer",
                location: "Finland",
                company: "Relex Solutions",
                start: "Dec 2022",
                end: "Present",
            },
            {
                title: "Product Designer",
                location: "Finland",
                company: "Happeo",
                start: "May 2022",
                end: "Oct 2022",
            },
        ]
    },
    {
        id: 10,
        fullName: "Soheil Ebrahimi",
        jobTitle: "Analytics Engineer",
        company: "Elli",
        experience: "6",
        location: ["Germany"],
        price: 100,
        about: "Process-oriented Analytics Engineer with more than 6 years of experience. Experienced in building robust data models, data pipelines, and data warehouses to help business executives trust the charts and reports.",
        social: {
            linkedin: "https://www.linkedin.com/in/soheil-ebrahimi-a03053169/",
        },
        topics: [
            "Technical Cv Feedback",
            "Technical Mock Interview",
            "Technical Task Feedback", "Sharing Experience"
        ],
        background: {
            languages: [
                "English", "Persian"
            ],
            expertise: "Business Intelligence",
        },
        experiences: [
            {
                title: "Analytics Engineer",
                location: "Germany",
                company: "Elli",
                start: "Apr 2024",
                end: "Present",
            },
            {
                title: "Analytics Engineer",
                location: "Germany",
                company: "HomeTiger",
                start: "Nov 2022",
                end: "Apr 2024",
            },
            {
                title: "Analytics Engineer",
                location: "Canada",
                company: "FlashBox",
                start: "Nov 2021",
                end: "Jan 2023",
            },
        ]
    },
    {
        id: 11,
        fullName: "Pooria Khavasi",
        jobTitle: "Product Manager",
        company: "Booking.com",
        experience: "6",
        location: ["The Netherlands"],
        price: 90,
        about: "I am a Product Manager with over 6 years of experience, having honed my skills in Strategy development, Experimentation, Data analysis and segmentation, User experience design, Roadmapping and dealing with ambiguity. \n" +
            "I've been PM in Booking.com for more than 2 years, working on the Personalized recommendations experience on key funnel pages and segment-specific features on mobile web. Before that, I was SPM in Alibaba travels and Snapp, working on customer facing products on Web and App platforms.",
        social: {
            linkedin: "https://linkedin.com/in/pooriakhavasi",
        },
        topics: [
            "Technical Cv Feedback",
            "Technical Mock Interview",
            "Technical Task Feedback", "Sharing Experience"
        ],
        background: {
            languages: [
                "English", "Persian"
            ],
            expertise: "Product",
        },
        experiences: [
            {
                title: "Product Manager",
                location: "The Netherlands",
                company: "Booking",
                start: "Dec 2021",
                end: "Present",
            },
            {
                title: "Senior Product Manager",
                location: "Iran",
                company: "Alibaba",
                start: "Dec 2020",
                end: "Nov 2021",
            },
        ]
    },
    {
        id: 12,
        fullName: "Soroush Owji",
        jobTitle: "FrontEnd Developer",
        company: "TomTom",
        experience: "7",
        location: ["The Netherlands"],
        price: 110,
        about: "I am a frontend developer with seven years of experience, specializing in ReactJS, VueJS, SASS, and modern JavaScript",
        social: {
            linkedin: "https://www.linkedin.com/in/soroush-owji/",
        },
        topics: [
            "Technical Cv Feedback",
            "Technical Mock Interview",
            "Technical Task Feedback", "Sharing Experience"
        ],
        background: {
            languages: [
                "English", "Persian"
            ],
            expertise: "Engineering",
        },
        experiences: [
            {
                title: "Senior Frontend Developer",
                location: "The Netherlands",
                company: "TomTom",
                start: "Jun 2023",
                end: "Present",
            },
            {
                title: "Senior Frontend Developer",
                location: "The Netherlands",
                company: "bunq",
                start: "Feb 2022",
                end: "May 2023",
            },
        ]
    },
    {
        id: 13,
        fullName: "Romina Rahimipour",
        jobTitle: "Product Designer",
        company: "Just Eat Takeaway",
        experience: "6",
        location: ["The Netherlands"],
        price: 90,
        about: "I'm an interdisciplinary designer with a focus on product design. who weathered release cycles and knows what details matter at each stage base on both the business and user sides. Interested in qualitative and quantitative data with a data-driven approach in design.  I also fancy creativity, innovation and cutting-edge routs. ",
        social: {
            linkedin: "https://www.linkedin.com/in/romina-rahimipour/",
        },
        topics: [
            "Technical Cv Feedback",
            "Technical Mock Interview",
            "Technical Task Feedback", "Sharing Experience"
        ],
        background: {
            languages: [
                "English", "Persian"
            ],
            expertise: "Product Design",
        },
        experiences: [
            {
                title: "Senior Product Designer",
                location: "The Netherlands",
                company: "JustEatTakeaway",
                start: "May 2023",
                end: "Present",
            },
            {
                title: "Senior Frontend Developer",
                location: "UAE",
                company: "grubtech",
                start: "May 2021",
                end: "Aug 2023",
            },
        ]
    },
]

export default mentors